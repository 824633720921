import React, { useEffect, useState } from 'react';
import logo from './psyko_skrubs.svg';
import smoke from './smoke2.gif';
import './App.css';
import './fonts/LEDLIGHT.otf';
import './fonts/LAMACHINECOMPANY.ttf';

const rawRosterData = [
  {"name": "buckethatwao", "image": "https://avatars.akamai.steamstatic.com/8c0a259e0e59e20330ab594361b1c3dd451d324a_full.jpg", "steam_id": "76561199406379718"},
  {"name": 'big pooch', "image": "https://avatars.akamai.steamstatic.com/384869ac051604e5225ce07e1b0d75808882044d_full.jpg", "steam_id": "76561199407891662"},
  {"name": 'camdino', "image": "https://avatars.akamai.steamstatic.com/9e99cdbe51a7326db2438153902e32e8d29ccff0_full.jpg", "steam_id": "76561198095332890"},
  {"name": 'GoldMember', "image": "https://avatars.akamai.steamstatic.com/01082617a1b062ddb8f81dff8e3c65b8ab4eebc7_full.jpg", "steam_id": "76561198089221644"},
  {"name": 'KaiserMikael', "image": "https://avatars.akamai.steamstatic.com/3453d43edd8275b7c963a027aed113b9bae6b9d5_full.jpg", "steam_id": "76561198054897590"},
  {"name": 'osteoporosis', "image": "https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1735420/6948ace06605aac9ab07548d22e72392aefc1278.gif", "steam_id": "76561198090034242"},
  {"name": 'sudo', "image": "https://avatars.akamai.steamstatic.com/aa4a44b09802e042e861229b6773112949aa1be0_full.jpg", "steam_id": "76561198090034242"},
  {"name": 'TheApeChief', "image": "https://avatars.akamai.steamstatic.com/49902d658f9eba9cf55691dd004ebf68ebf49717_full.jpg", "steam_id": "76561198086645266"},
];

function App() {
  const [lastUpdatedAtEpoch, setLastUpdatedAtEpoch] = useState(null);
  const [rosterData, setRosterDate] = useState(rawRosterData);
  const [data, setData] = useState(null);

  useEffect(() => {
    // Make a server-side request here
    fetch(`https://${process.env.REACT_APP_PSYKO_API_URL}/all_info`)
      .then(response => response.json())
      .then(data => {
        // Update state with the fetched data
        setData(data);
        const playerStats = data['player_stats'];
        var newRosterdata = rosterData.map(member => ({
          ...member,
          player_stats: playerStats[member.name]
        }));
        // Sort rosterData by rank in descending order
        newRosterdata.sort((a, b) => b.player_stats.rank - a.player_stats.rank);
        setRosterDate(newRosterdata);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

      fetch(`https://${process.env.REACT_APP_PSYKO_API_URL}/last_crawl_started_at`)
      .then(response => response.json())
      .then(data => {
        setLastUpdatedAtEpoch(data?.last_crawl_started_at);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // The empty dependency array means this effect runs once after the initial render

  function formatTime(time) {
    var epochTime = new Date(0);
    epochTime.setUTCSeconds(time);
    return epochTime.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute:'2-digit'
    });
  }

  return (
    <div className='pageContainer'>
      <img src={logo} alt="logo" className="psykoLogo"/>
      <h1 className="rosterLastUpdatedText">Last Updated At: {formatTime(lastUpdatedAtEpoch)}</h1>
      {rosterData.map((member, index) => (
        <div key={index} className="rosterRowContainer">
          <div className="rosterRow">
            <a href={`https://csstats.gg/player/${member.steam_id}`} target="_">
              <img src={member.image} alt="member_image" className='rosterRowPic'/>
            </a>
          </div>
          <div className="rosterRowText">
            <h1 className="rosterRowNameText">{member.name.padStart(30, ' ')}</h1>
            {member.player_stats && member.player_stats.rank && (
              <h1 className="rosterRowRankText">Rank: {Number(member.player_stats.rank)?.toLocaleString().padStart(30, ' ')}</h1>
            )}
            {member.player_stats && member.player_stats.overall && member.player_stats.overall.kpd && (
              <h1 className="rosterRowRankText">K/D: {String(member.player_stats.overall.kpd).padStart(30, ' ')}</h1>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default App;
